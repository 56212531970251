// main: ../master.scss
// The dark themed sidebar for the dashboard
.WebScrapings {
  @include fadeIn();
  background: $whiteColor;
  min-height: 300px;

  .FormBlock {
    padding: 50px $mainPaddingSize;
    background: $offWhiteColor;

    .WebScrapingsTitle {
      @include fontSizeTitle();
      padding-bottom: 25px;

      img {
        height: 30px;
        margin-right: 20px;
        background: $whiteColor;
        padding: 5px;
        border: 1px solid $grayColor0;
        border-radius: 15px;
        @include pushable();
        transition: all 300ms;

        &:hover {
          background: $grayColor0;
        }
      }
    }

    .WebScrapingsContent {
      width: 100%;

      .WebScrapingsFilters {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;

        .SettingsToggle {
          padding: 0px 8px;
        }
      }
      .WebScrapingsLegend {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: center;

        .WebScrapingsLegendItemBorder {
          width: 2px;
          height: 16px;
          background: $grayColor0;
          margin: 0px 8px;
        }
        .WebScrapingsLegendItem {
          display: flex;
          flex-flow: row nowrap;
          justify-content: flex-start;
          align-items: center;
          margin: 8px;
          @include fontSizeNormal();
          color: $grayColor2;
          .WebScrapingsLegendItemKey {
            padding-right: 4px;
          }
          .WebScrapingsLegendItemValue {

          }
        }
      }
      .WebScrapingNote {
        background: $greenColor;
        border: 1px solid darken($greenColor, 10%);
        border-radius: 12px;
        padding: 20px;
        @include  fontSizeNormal();
      }
      .WebScrapingNoteAlt {
        background: $seafoamColor;
        border: 1px solid darken($seafoamColor, 10%);
      }
      .WebScrapingsContentError {
        @include fontSizeStat();
        color: $themeColor3;
      }
      .WebScrapingsContentActions {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: center;
        margin-top: 100px;

        div {
          margin-right: 20px;
          margin-bottom: 20px;
        }
      }
      .WebScrapingsContentText {
        @include fontSizeNormal();
        color: $grayColor1;
      }
      .WebScrapingsContentActionsCentered {
        justify-content: center;
        margin-top: 20px;
      }

      .WebScrapingsContentHeader {
        width: 100%;
        text-align: left;

        .WebScrapingsContentHeaderType {
          @include fontSizeTitle();
        }
        .WebScrapingsContentHeaderBaseURL {
          @include fontSizeNormal();
          color: $themeColor2;
          text-decoration: underline;
          display: block;
          margin: 5px 0px;
        }
        .WebScrapingsContentHeaderStatus {
          @include fontSizeMedium();
          margin: 5px 0px;
          padding: 0px 0px 5px 0px;
          border-bottom: 2px solid $grayColor0;
          display: inline-block;
        }
        .WebScrapingsContentHeaderCount {
          @include fontSizeMedium();
          font-weight: bold;
          color: $grayColor0;
          margin: 5px 0px;
        }
        .WebScrapingsContentHeaderTotalPages {
          @include fontSizeMedium();
          font-weight: bold;
          color: $themeColor2;
          margin: 5px 0px;
        }
        .WebScrapingsContentHeaderTotalMassReview {
          @include fontSizeMedium();
          font-weight: bold;
          color: $orangeColor;
          margin: 5px 0px;
        }
        .WebScrapingsContentHeaderTotalReview {
          @include fontSizeMedium();
          font-weight: bold;
          color: $purpleColor;
          margin: 5px 0px;
        }
        .WebScrapingsContentHeaderRejected {
          @include fontSizeMedium();
          font-weight: bold;
          color: $grayColor1;
          margin: 5px 0px;
        }
        .WebScrapingsContentHeaderReadyToCreate {
          @include fontSizeMedium();
          font-weight: bold;
          color: $themeColor1;
          margin: 5px 0px;
        }
        .WebScrapingsContentHeaderCreated {
          @include fontSizeMedium();
          font-weight: bold;
          color: $themeColor2;
          margin: 5px 0px;
        }
        .WebScrapingsContentHeaderTimeRemaining {
          @include fontSizeNormal();
          font-weight: bold;
          color: $whiteColor;
          margin: 5px 0px;
          display: inline-block;
          padding: 2px 8px;
          border-radius: 17px;
          background: $themeColor1;
        }
        .WebScrapingsContentHeaderStatusDescription {
          @include fontSizeNormal();
        }
      }
      
      .WebScrapingsContentReview {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: flex-start;
        .WebScrapingsContentReviewLeft {
          flex-basis: 50%;
        }
        .WebScrapingsContentReviewRight {
          flex-basis: 50%;
          height: 1000px;

          iframe {
            height: 100%;
            width: 100%;
            border: 0px;
            @include materialThickShadow();
          }
        }
      }
      .WebScrapingsContentMassReviews {
        text-align: center;
        width: 100%;

        .WebScrapingsContentMassReviewsLeft {
          width: 100%;
          display: flex;
          flex-flow: column nowrap;
          justify-content: center;
          align-items: center;

          .WebScrapingsContentMassReviewsAll {
            text-align: center;
            margin-bottom: 20px;
            padding-bottom: 20px;
            border-bottom: 2px solid $grayColor0;
          }
          .WebScrapingsContentMassReview {
            width: 100%;
            max-width: 600px;
            background-color: $whiteColor;
            border-radius: 12px;
            @include boxShadow();
            margin-bottom: 20px;
            overflow: hidden;

            .WebScrapingsContentMassReviewCheck {
              margin-right: 10px;

              img {
                @include pushable();
                height: 20px;
              }
            }
            .WebScrapingsContentMassReviewData {
              display: flex;
              flex-flow: column nowrap;
              justify-content: center;
              align-items: flex-start;
              text-align: left;
              cursor: pointer;

              .WebScrapingsContentMassReviewDataImage {
                width: 100%;
                object-fit: contain;
              }
              .WebScrapingsContentMassReviewDataText {
                margin: 8px;
                display: flex;
                flex-flow: row nowrap;
                justify-content: center;
                align-items: flex-start;

                .WebScrapingsContentMassReviewDataTextLeft {
                  flex-basis: 40px;
                  height: 80px;
                  margin-right: 8px;
                  @include pushable();
                  text-align: center;

                  .WebScrapingsContentMassReviewDataTextLeftEmoji {
                    @include fontSizeStat();
                    color: $darkColor;
                    margin-bottom: 6px;
                  }

                  img {
                    width: 44px;
                    padding: 12px;
                    background-color: $offWhiteColor2;
                    border-radius: 8px;
                  }
                }
                .WebScrapingsContentMassReviewDataTextRight {
                  flex-basis: calc(100% - 48px);
                  .WebScrapingsContentMassReviewDataTitle {
                    @include fontSizeMedium();
                    color: $darkColor;
                  }
                  .WebScrapingsContentMassReviewDataWhen {
                    @include fontSizeNormal();
                    color: $grayColor1;
                  }
                  .WebScrapingsContentMassReviewDataWhere {
                    @include fontSizeNormal();
                    color: $grayColor1;
                  }
                  .WebScrapingsContentMassReviewDataPrice {
                    @include fontSizeNormal();
                    font-weight: bold;
                    color: $darkColor;
                  }
                  .WebScrapingsContentMassReviewDataSummary {
                    @include fontSizeNormal();
                    color: $grayColor1;
                  }
                }
              }
            }
          }
          .WebScrapingsContentMassReviewAccepted {
            background-color: lighten($greenColor, 10%);
          }
        }
        .WebScrapingsContentMassReviewsRight {
          flex-basis: calc(50% - 5px);
          margin-left: 5px;
          padding-top: 70px;

          iframe {
            width: 100%;
            height: 1000px;
            border: 0px;
            @include materialThickShadow();
          }
        }
      }
    }

    .EmojiTags {
      flex-flow: column;
      justify-content: center;
      align-items: center;
      width: 100%;

      .EmojiTagsCounts {
        @include fontSizeNormal();
        color: $grayColor3;
        padding-bottom: 4px;

        span {
          color: $themeColor3;
        }
      }

      .EmojiTagsActions {
        margin: 20px 0px;
        text-align: center;
        width: 100%;
      }

      .EmojiTagsFilters {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;

        .SettingsToggle {
          padding: 0px 8px;
        }
      }

      .EmojiTag {
        background-color: $whiteColor;
        padding: 12px;
        margin-bottom: 2px;
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: center;

        .EmojiTagEmoji {

          input {
            @include fontSizeMedium();
            color: $darkColor;
            padding: 4px;
            margin: 0px;
            border: 2px solid $offWhiteColor2;
            border-radius: 4px;
            width: 40px;
            text-align: center;
          }
        }
        .EmojiTagEmojiUnfilled {
          input {
            border: 2px solid $themeColor3;
          }
        }
        .EmojiTagName {
          @include fontSizeMedium();
          color: $darkColor;
          margin-left: 8px;
          background-color: $offWhiteColor2;
          padding: 2px 6px;
          border-radius: 12px;
          min-width: 24px;
          min-height: 24px;
        }
        .EmojiTagCount {
          @include fontSizeSmall();
          color: $whiteColor;
          margin-left: 8px;
          background-color: $themeColor2;
          padding: 2px 6px;
          border-radius: 12px;
          min-width: 24px;
        }
        .EmojiTagPossibles {
          display: flex;
          flex-flow: row wrap;
          max-width: 200px;

          .EmojiTagPossible {
            padding: 2px;
            @include fontSizeMedium();
            cursor: pointer;
            @include noselect();
          }
        }
      }
    }
  }

  @media (max-width: $breakSmall) {
    min-height: 300px;
    .FormBlock {
      padding: 20px $breakPaddingSize;

      .WebScrapingsContent {
        .WebScrapingsContentReview {
          flex-flow: row wrap;
          .WebScrapingsContentReviewLeft {
            flex-basis: 100%;
          }
          .WebScrapingsContentReviewRight {
            flex-basis: 100%;

            iframe {
              height: 600px;
            }
          }
        }
        .WebScrapingsContentMassReviews {
          flex-flow: row wrap;
          .WebScrapingsContentMassReviewsLeft {
            flex-basis: 100%;
          }
          .WebScrapingsContentMassReviewsRight {
            flex-basis: 100%;

            iframe {
              height: 600px;
            }
          }
        }
      }
    }
  }
}
