// main: ../master.scss
@keyframes candymove {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}

// The dark themed sidebar for the dashboard
.Profile {
  @include fadeIn();
  background: $whiteColor;
  min-height: 300px;

  .ProfileTab {
    background: $offWhiteColor;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    padding: 10px $mainPaddingSize;

    .ProfileTabItem {
      @include pushable();
      padding: 0px 20px;
      color: $grayColor1;
    }
    .ProfileTabItemActive {
      color: $darkColor;
      font-weight: bold;
    }
  }
  .FormBlock {
    padding: 50px $mainPaddingSize;
    background: $offWhiteColor;

    .ProfileFormTitle {
      @include fontSizeTitle();
      padding-bottom: 25px;
    }
  }

  @media (max-width: $breakSmall) {
    min-height: 300px;
    .ClassPurchases {
      padding: 10px $breakPaddingSize;
    }
    .InstructorPayouts {
      padding: 10px $breakPaddingSize;
    }
    .InstructorAdmin {
      padding: 10px $breakPaddingSize;
    }
    .InstructorConnectVenmo {
      padding: 10px $breakPaddingSize;
    }
    .ProfileTab {
      padding: 10px $breakPaddingSize;
    }
    .ProfileEditBar {
      padding: 0px 0px 0px $breakPaddingSize;
    }
    .ProfileForm {
      padding: 50px $breakPaddingSize;
    }
    .ClassList {
      padding: 50px $breakPaddingSize;

      .ClassListClass {
        .ClassListClassVideo {
          flex-basis: calc($breakSmall / 2);
        }
        .ClassListClassContent {
          flex-basis: calc($breakSmall / 2);
          padding: 0px 0px 25px 0px;
        }
      }
    }
    .InstructorProfile {
      .InstructorProfileTop {
        .InstructorProfileContent {
          left: $breakPaddingSize;
        }
      }
      .InstructorProfileAbout {
        .InstructorProfileAboutLeft {
          flex-basis: 100%;
          padding-left: $breakPaddingSize;
        }
        .InstructorProfileAboutRight {
          flex-basis: 100%;
        }
      }
    }
    .InstructorProfileClasses {
      .InstructorProfileClass {
        .InstructorProfileClassLeft {
          flex-basis: 100%;
        }
        .InstructorProfileClassRight {
          flex-basis: 100%;
        }
      }
    }
  }
}

.VideoPlayerDivPreview {
  width: 100%;
  height: 100%;

  .VideoPlayerDivPreviewImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.VideoPlayerDiv {
  height: 450px;
  max-width: 100%;
  //background: $darkColor;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  //border-radius: 20px;
  overflow: hidden;

  .VideoPlayerDivContainer {
    //background: $darkColor;
    width: 100% !important;
    height: 100%;

    iframe {
      width: 100% !important;
      height: 100% !important;
    }
  }
  .VideoPlayerDivError {
    background: $darkColor;
    color: $whiteColor;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    .VideoPlayerDivErrorTitle {
      @include fontSizeTitle();
      padding-bottom: 12px;
    }
    .VideoPlayerDivErrorDescription {
      @include fontSizeNormal();
    }
  }
}
.VideoPlayerDivLoading {
  .VideoPlayerDivContainer {
    background: $darkColor;
  }
}

@media (max-width: 1440px) {
  .VideoPlayerDiv {
    height: 400px;
  }
}
@media (max-width: 1200px) {
  .VideoPlayerDiv {
    height: 350px;
  }
}
@media (max-width: 1024px) {
  .VideoPlayerDiv {
    height: 300px;
  }
}
@media (max-width: $breakSmall) {
  .VideoPlayerDiv {
    height: 450px;
  }
}
@media (max-width: 700px) {
  .VideoPlayerDiv {
    height: 400px;
  }
}
@media (max-width: 600px) {
  .VideoPlayerDiv {
    height: 350px;
  }
}
@media (max-width: 500px) {
  .VideoPlayerDiv {
    height: 300px;
  }
}
@media (max-width: 414px) {
  .VideoPlayerDiv {
    height: 250px;
  }
}
@media (max-width: 320px) {
  .VideoPlayerDiv {
    height: 180px;
  }
}
