// main: ../master.scss

// The dark themed sidebar for the dashboard
.Login {
  padding: $mainPadding;
  @include fadeIn();
  background: $offWhiteColor;
  min-height: calc(100% - 100px);

  @media (max-width: $breakSmall) {
    padding: $breakPadding;
    min-height: calc(100% - 48px);
  }
}

.FormBlock {
  width: 100%;
  height: 100%;
  text-align: center;
  padding-bottom: 60px;

  .FormBlockTitle {
    @include fontSizeTitle();
    color: $darkColor;
    padding: 60px 0px 60px 0px;
  }
  .FormBlockDescription {
    @include fontSizeMedium();
    color: $darkColor;
    padding: 0px 0px 60px 0px;
  }
  .FormBlockInput {
  }
  .FormLoader {
    height: 88px;
  }
  .FormBlockText {
    padding: 40px 0px;
    @include fontSizeNormal();
    color: $grayColor0;
  }
  .LoginWithFacebook {
    margin-bottom: 25px;
    @include pushable();
    background: $facebookColor;
    &:hover {
      @include boxShadow();
    }
  }
  .LoginWithGoogle {
    margin-bottom: 25px;
    @include pushable();
    background: $whiteColor;
    color: $darkColor;
    @include boxShadowLight();
    &:hover {
      @include boxShadow();
    }
  }
  .FormBlockLinks {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;

    .FormBlockLink {
      @include fontSizeNormal();
      font-weight: 600;
      color: $grayColor0;
      padding: 0px 12px;
      @include pushable();
      transition: all 0.5s;

      &:hover {
        color: $darkColor;
      }
    }
  }
}
.FormBlockButton {
  display: inline-block;
  padding: 15px 30px;
  height: 50px;
  min-width: 180px;
  border-radius: 4px;
  @include fontSizeNormal();
  color: $whiteColor;
  background: $grayColor0;
  transition: all 500ms;
  text-align: center;
  @include pushable();

  img {
    width: 30px;
    vertical-align: top;
    margin-right: 8px;
    margin-top: -5px;
    margin-left: -18px;
  }
}
.FormBlockButtonActive {
  background: $darkColor;
  color: $themeColor1;
  &:hover {
    @include boxShadow();
  }
}
.FormBlockButtonTheme {
  background: $themeColor1;
  color: $whiteColor;
  &:hover {
    @include boxShadow();
  }
}
.FormBlockButtonTheme2 {
  background: $themeColor2;
  color: $whiteColor;
  &:hover {
    @include boxShadow();
  }
}
.FormBlockButtonPurple {
  background: $purpleColor;
  color: $whiteColor;
  &:hover {
    @include boxShadow();
  }
}
.FormBlockButtonOrange {
  background: $orangeColor;
  color: $whiteColor;
  &:hover {
    @include boxShadow();
  }
}
.FormBlockButtonDelete {
  background: $themeColor3;
  color: $whiteColor;
  &:hover {
    @include boxShadow();
  }
}
.FormBlockButtonWhite {
  background: $whiteColor;
  color: $darkColor;
  &:hover {
    @include boxShadow();
  }
}
.FormBlockButtonOutline {
  background: $whiteColor;
  color: $darkColor;
  border: 2px solid $darkColor;
}
.FormBlockButtonText {
  background: $whiteColor;
  color: $darkColor;
  &:hover {
    @include boxShadow();
  }
}
.FormBlockButtonSmall {
  min-width: 120px;
}

.FormBlockOptions {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;

  .FormBlockOption {
    color: $grayColor0;
    margin: 0px 20px;
    @include fontSizeMedium();
    font-weight: 600;
    border-bottom: 2px solid transparent;
    @include pushable();
    transition: all 0.5s;
  }
  .FormBlockOptionSelected {
    color: $darkColor;
    border-bottom: 2px solid $darkColor;
  }
}
