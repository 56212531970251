// main: ../master.scss
// The dark themed sidebar for the dashboard
.ManageArticle {
  @include fadeIn();
  background: $whiteColor;
  min-height: 300px;

  .FormBlock {
    padding: 50px $mainPaddingSize;
    background: $offWhiteColor;

    .ManageArticleTitle {
      @include fontSizeTitle();
      padding-bottom: 25px;

      img {
        height: 30px;
        margin-right: 20px;
        background: $whiteColor;
        padding: 5px;
        border: 1px solid $grayColor0;
        border-radius: 15px;
        @include pushable();
        transition: all 300ms;

        &:hover {
          background: $grayColor0;
        }
      }
    }

    .ManageArticleContent {
      width: 100%;
      text-align: left;

      .ManageArticleContentError {
        @include fontSizeStat();
        color: $themeColor3;
      }
      .ManageArticleContentTitle {
        @include fontSizeStat();
        font-weight: bold;
        color: $darkColor;
        padding-bottom: 12px;
        display: inline-block;
        @include pushable();

        .ManageArticleContentTitleArrow {
          padding: 0px 8px;
        }

        .ManageArticleContentSubloader {
          display: inline-block;
          padding-left: 12px;
          img {
            height: 30px;
            @include spin(2s);
          }
        }
      }
      .ManageArticleMetadataSettings {
        padding-bottom: 32px;

        .AuthorBlockHeader {
          @include fontSizeMid();
          color: $darkColor;
        }
        .AuthorBlock {
          padding: 12px 0px;
          display: flex;
          flex-flow: row nowrap;
          justify-content: flex-start;
          align-items: center;

          .AuthorBlockImage {
            img {
              width: 60px;
              height: 60px;
              object-fit: cover;
              border-radius: 30px;
            }
          }
          .AuthorBlockName {
            @include fontSizeNormal();
            color: $grayColor0;
            padding-left: 12px;
          }
        }
      }

      .BlogEditor {
        background: $whiteColor;
        padding: 12px;
        border-radius: 40px;

        .BlogEditorTop {
          text-align: center;
          background: $offWhiteColor;
          margin: -12px;
        }

        .BlogEditorContent {
          max-width: 700px;
          margin: 12px auto;
          padding: 40px 0px;

          .BlogEditorContentTitle {

            .inputBottomLineLightContainer {
              width: 100%;

              .inputBottomLineLightFlexBox {
                width: 100%;
                .inputBottomAreaLight {
                  width: 100%;
                  border: 0px;
                  resize: none;

                  @include fontSizeHuge();
                  font-size: 55px;
                  color: $darkColor;

                  @media (max-width: 600px) {
                    font-size: 32px;
                  }
                }
                input:focus, textarea {
                  outline: none !important;
                }
              }
            }
          }
          .BlogEditorContentSubtitle {
            padding: 12px 0px;

            .inputBottomLineLightContainer {
              width: 100%;

              .inputBottomLineLightFlexBox {
                width: 100%;
                .inputBottomAreaLight {
                  width: 100%;
                  border: 0px;
                  resize: none;

                  @include fontSizeStat();
                  color: $grayColor1;
                }
                input:focus, textarea {
                  outline: none !important;
                }
              }
            }
          }
          .BlogEditorContentAuthor {
            border-top: 1px solid $offWhiteColor;
            padding: 24px 0px 40px 0px;
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            align-items: center;

            .BlogEditorContentAuthorImage {
              img {
                width: 60px;
                height: 60px;
                object-fit: cover;
                border-radius: 30px;
              }
            }
            .BlogEditorContentAuthorContent {
                padding: 0px 0px 0px 12px;
              .BlogEditorContentAuthorContentName {
                @include fontSizeNormal();
                color: $darkColor;
              }
              .BlogEditorContentAuthorContentPublished {
                @include fontSizeNormal();
                color: $grayColor0;
              }
            }
          }
          .BlogEditorContentMainImage {
            width: 100%;
            text-align: center;

            .inputBottomLineLightContainer {
              width: 100%;
              .FileSelectorDiv {
                padding: 0px;
                text-align: center;

                .FileSelectorDivImage {
                  margin: 0px;
                  display: block;
                  width: 100%;
                  height: auto;
                  object-fit: cover;
                  box-shadow: none;
                }
                .FormBlockButtonTheme {
                  margin-top: 16px;
                }
              }
            }
          }
          .BlogEditorContentBlocks {
            .BlogEditorContentBlock {
              .BlogEditorContentBlockToolbar {
                display: flex;
                flex-flow: row nowrap;
                justify-content: flex-start;
                align-items: center;
                padding-bottom: 4px;

                .BlogEditorContentBlockToolbarItem {
                  margin-right: 8px;
                  @include fontSizeSmall();
                  color: $darkColor;
                  @include pushable();

                  img {
                    height: 22px;
                    object-fit: contain;
                  }
                }
                .BlogEditorContentBlockToolbarDelete {
                }
                .BlogEditorContentBlockToolbarMoveUp {
                }
                .BlogEditorContentBlockToolbarMoveDown {
                }
              }
              .BlogEditorContentBlockHeader {
                .inputBottomLineLightContainer {
                  width: 100%;

                  .inputBottomLineLightFlexBox {
                    width: 100%;
                    .inputBottomAreaLight {
                      width: 100%;
                      border: 0px;
                      resize: none;

                      @include fontSizeHuge();
                      font-size: 32px;
                      color: $darkColor;

                      @media (max-width: 600px) {
                        font-size: 24px;
                      }
                    }
                    input:focus, textarea {
                      outline: none !important;
                    }
                  }
                }
              }
              .BlogEditorContentBlockText {
                .EasyMDEContainer {
                  .editor-toolbar {
                    border: 0px solid $offWhiteColor2;
                    border-bottom: 1px solid $offWhiteColor2;
                    z-index: 1001;
                  }
                  .CodeMirror {
                    border: 0px solid $offWhiteColor2;
                    border-bottom: 1px solid $offWhiteColor2;
                    border-radius: 0px;
                    font-size: 18px;
                    z-index: 1000;

                    .CodeMirror-scroll {
                      min-height: 50px !important;
                    }
                  }
                  padding-bottom: 12px;
                }
              }
              .BlogEditorContentBlockImage {
                width: 100%;
                text-align: center;

                .inputBottomLineLightContainer {
                  width: 100%;
                  .FileSelectorDiv {
                    padding: 0px;
                    text-align: center;

                    .FileSelectorDivImage {
                      margin: 0px;
                      display: block;
                      width: 100%;
                      height: auto;
                      object-fit: cover;
                      box-shadow: none;
                    }
                    .FormBlockButtonTheme {
                      margin-top: 16px;
                    }
                  }
                }
              }

              .BlogEditorContentBlockVideo {
                .inputBottomLineLightContainer {
                  width: 100%;

                  .inputBottomLineLightFlexBox {
                    width: 100%;
                    .inputBottomLineLight {
                      width: 100%;
                    }
                    input:focus, textarea {
                      outline: none !important;
                    }
                  }
                }
              }
            }


            .BlogEditorContentBlocksToolbar {
              display: flex;
              flex-flow: row wrap;
              justify-content: center;
              align-items: center;

              .BlogEditorContentBlocksToolbarItem {
                margin: 4px;
                color: $darkColor;
                @include fontSizeMedium();
                @include pushable();

                img {
                  height: 44px;
                  object-fit: contain;
                }
              }
              .BlogEditorContentBlocksToolbarShow {
                margin-right: 30px;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: $breakSmall) {
    min-height: 300px;
    .FormBlock {
      padding: 20px $breakPaddingSize;

      .ManageArticleContent {
      }
    }
  }
}
