// main: ../master.scss
// The dark themed sidebar for the dashboard
.CreateGeoji {
  @include fadeIn();
  background: $whiteColor;
  min-height: 300px;

  .CreatedGeoji {
    text-align: center;
    .CreatedGeojiTitle {
      @include fontSizeTitle();
    }
    .CreatedGeojiDescription {
      @include fontSizeNormal();
      padding: 24px 0px;
    }
  }

  .FormBlock {
    padding: 50px $mainPaddingSize;
    background: $offWhiteColor;

    .CreateGeojiTitle {
      @include fontSizeTitle();
      padding-bottom: 25px;

      img {
        height: 30px;
        margin-right: 20px;
        background: $whiteColor;
        padding: 5px;
        border: 1px solid $grayColor0;
        border-radius: 15px;
        @include pushable();
        transition: all 300ms;

        &:hover {
          background: $grayColor0;
        }
      }
    }

    .CreateGeojiContent {
      width: 100%;
    }
  }

  @media (max-width: $breakSmall) {
    min-height: 300px;
    .FormBlock {
      padding: 20px $breakPaddingSize;

      .CreateGeojiContent {
      }
    }
  }
}

.EmojiList {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 350px;
  margin: 0px auto;
  overflow-x: scroll;
  height: 55px;

  .EmojiListTitle {
    @include fontSizeNormal();
    padding-right: 12px;
  }

  .EmojiItem {
    @include fontSizeTitle();
    padding: 2px 8px;
    background: $offWhiteColor2;
    border-radius: 12px;
    margin-right: 8px;
    @include  pushable();
  }
}

@media (max-width: 460px) {
  .EmojiList {
    width: 260px;
  }
}
