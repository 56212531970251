// main: ../master.scss
$menuLineHeight: 2px;
$menuLineWidth: 24px;

.Home {
  position: relative;
  padding: 0px;
  margin: 0px;

  .HomeTop {
    .HomeTopBackground {
      z-index: 0;
      width: 100%;
      height: 800px;

      background-image: url('../images/PPB/PPBWebsiteBackground.jpg');
      background-size: cover;
      background-position: top;
      background-repeat: no-repeat;

      @media screen and (max-width: 2300px) {
        height: 700px;
      }

      @media screen and (max-width: 2000px) {
        height: 600px;
      }

      @media screen and (max-width: 1700px) {
        height: 500px;
      }

      @media screen and (max-width: 1340px) {
        height: 400px;
      }

      @media screen and (max-width: 740px) {
        height: 400px;
        background-image: url('../images/PPB/PPBWebsiteBackgroundSmall.jpg');
      }
    }
  }
  .HomeTopLinks {
    position: absolute;
    z-index: 10;
    top: 24px;
    left: 0px;
    width: 100%;
    text-align: right;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    align-items: center;

    .HomeTopLink {
      flex-grow: 0;
      @include fontSizeNormal();
      @include maecenasFont();
      color: $ppbBackground;
      text-transform: uppercase;
      padding: 8px 24px;
      margin-right: 12px;
      transition: all 0.5s;
      vertical-align: text-bottom;
      border-radius: 30px;

      &:hover {
        background-color: $ppbBackground;
        color: $ppbText;
        text-decoration: none;
      }

      @media screen and (max-width: 950px) {
        display: none;
      }
    }
    .HomeTopLinkSpacer {
      flex-grow: 1;
    }
    
    .TopBarMenu {
      flex-grow: 0;
      margin: 0px 12px;
      width: $menuLineWidth;
      @include pushable();
      display: none;

      .menu-btn {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: $menuLineWidth;
        height: 15px;
        transition: all .5s ease-in-out;
  
        .menu-btn__burger {
          width: $menuLineWidth;
          height: $menuLineHeight;
          background: $ppbBackground;
          border-radius: calc($menuLineHeight / 2);
          transition: all .5s ease-in-out;
        }
        .menu-btn__burger::before,
        .menu-btn__burger::after {
          content: '';
          position: absolute;
          left: 0px;
          width: $menuLineWidth;
          height: $menuLineHeight;
          background: $ppbBackground;
          border-radius: calc($menuLineHeight / 2);
          transition: all .5s ease-in-out;
        }
        .menu-btn__burger::before {
          transform: translateY(-8px);
        }
        .menu-btn__burger::after {
          transform: translateY(8px);
        }
      }
      /* ANIMATION */
      .menu-btn.open .menu-btn__burger {
        transform: translateX(-50px);
        background: transparent;
        box-shadow: none;
      }
      .menu-btn.open .menu-btn__burger::before {
        transform: rotate(45deg) translate(35px, -35px);
      }
      .menu-btn.open .menu-btn__burger::after {
        transform: rotate(-45deg) translate(35px, 35px);
      }

      @media screen and (max-width: 950px) {
        display: block;
      }
    }

    .HomeTopLinks2 {
      display: none;
    }
  }
  .HomeTopLinksOpen {
    position: fixed !important;
    background-color: transparentize($ppbBackground, 0.05);
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 900;
    align-items: flex-end;
    justify-content: flex-start;
    flex-flow: column wrap;
    padding-top: 24px;

    .HomeTopLinkSpacer {
      display: none;
    }

    .TopBarMenu {

      .menu-btn {
  
        .menu-btn__burger {
          background: $ppbText;
        }
        .menu-btn__burger::before,
        .menu-btn__burger::after {
          background: $ppbText;
        }
      }
    }

    .HomeTopLinks2 {
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      align-items: flex-start;
      width: 100%;
      padding: 20px 0px 20px 0px;
      .HomeTopLink2 {
        @include fontSizeMedium();
        @include maecenasFont();
        color: $ppbText;
        padding: 12px;
        transition: all 0.5s;

        &:hover {
          text-decoration: none;
          color: $ppbLink;
        }
      }
    }
  }

  .HomeBusinesses {
    background-color: $ppbBackground;
    padding: 10px;
    position: relative;
    z-index: 11;
    .HomeBusinessesContent {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: flex-start;

      .HomeBusiness {
        padding: 10px;
        width: 262px;
        text-align: center;
        
        .HomeBusinessImageBox {
          box-shadow: 0px 2px 4px 0px transparentize($darkColor, 0.80);
          //@include boxShadow();
          width: 90%;
          margin: 0px 5%;
          height: 150px;
          border-radius: 5px;
          .HomeBusinessImage {
            width: 100%;
            height: 100%;
            border-radius: 5px;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
        .HomeBusinessTitle {
          @include fontSizeStat();
          @include maecenasFont();
          font-weight: 300;
          color: $ppbText;
        }
        .HomeBusinessDescription {
          @include fontSizeNormal();
          @include maecenasFont();
          color: $ppbText;
          padding-top: 8px;
        }
        .HomeBusinessLink {
          @include fontSizeNormal();
          @include maecenasFont();
          color: $ppbText;
          background-color: $ppbBackground;
          padding: 4px 12px;
          margin: 4px 0px 0px 0px;
          display: inline-block;
          border: 2px solid $ppbText;
          border-radius: 8px;
          transition: all 0.5s;

          &:hover {
            text-decoration: none;
            background-color: $ppbText;
            color: $ppbBackground;
          }
        }
      }
    }
  }

  .FeaturedEvents {
    text-align: center;
    width: 100%;
    background-color: #2D3A39;
    background-image: url('../images/PPB/PumpkinBackground.jpg');
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    padding-bottom: 20px;

    .FeaturedEventsTitle {
      @include fontSizeTitle();
      @include maecenasFont();
      font-weight: normal;
      color: $ppbTextLight;
      padding: 10px;
    }
    .FeaturedEventsList {
      text-align: center;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
      .FeaturedEvent {
        width: 600px;
        height: 400px;
        margin: 10px;
        position: relative;
        @include boxShadow();
        border-radius: 12px;
        overflow: hidden;
        cursor: pointer;
        .FeaturedEventImage {
          position: absolute;
          z-index: 1;
          overflow: clip;
          width: 100%;
          height: 100%;
          border-radius: 12px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: all 0.3s;
          }
        }
        .FeaturedEventContent {
          position: absolute;
          bottom: 0px;
          padding: 20px 10px 10px 10px;
          width: calc(100%);
          z-index: 2;
          text-align: left;
          border-radius: 12px;
          overflow: hidden;
          background: rgb(45,58,57);
          background: -moz-linear-gradient(0deg, rgba(45,58,57,0.8981967787114846) 0%, rgba(45,43,41,0) 100%);
          background: -webkit-linear-gradient(0deg, rgba(45,58,57,0.8981967787114846) 0%, rgba(45,43,41,0) 100%);
          background: linear-gradient(0deg, rgba(45,58,57,0.8981967787114846) 0%, rgba(45,43,41,0) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2d3a39",endColorstr="#2d2b29",GradientType=1);

          .FeaturedEventDescription {
            @include fontSizeNormal();
            @include maecenasFont();
            color: $ppbTextLight;
            padding-top: 20px;
          }
          .FeaturedEventTitle {
            @include fontSizeStat();
            @include maecenasFont();
            //font-weight: bold;
            color: $ppbTextLight;
          }
        }

        &:hover {
          .FeaturedEventImage {
            img {
              width: 110%;
              margin-left: -5%;
            }
          }
        }

        @media screen and (max-width: 620px) {
          height: 300px;
        }
        @media screen and (max-width: 500px) {
          height: 250px;
        }
        @media screen and (max-width: 400px) {
          height: 200px;
        }
        @media screen and (max-width: 320px) {
          height: 150px;
        }
      }
    }
  }

  .Partners {
    background-color: $whiteColor;
    .PartnersTitle {
      @include fontSizeTitle();
      @include maecenasFont();
      color: $ppbText;
      text-align: center;
    }
    .PartnersWork {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
      .Partner {
        width: 100px;
        height: 100px;
        margin: 10px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
    hr {
      border-top: 4px solid $ppbBackground;
      margin: 0px 10px;
    }
    .PartnersCities {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
      .Partner {
        width: 100px;
        height: 100px;
        margin: 10px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  .AboutUs {
    width: 100%;
    height: 1000px;

    background-image: url("../images/PPB/AboutUs.jpg");
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;

    div {
      visibility: hidden;
    }

    @media screen and (max-width: 2300px) {
      height: 900px;
    }

    @media screen and (max-width: 1700px) {
      height: 650px;
    }

    @media screen and (max-width: 1500px) {
      height: 600px;
    }

    @media screen and (max-width: 1200px) {
      height: 600px;
    }
    @media screen and (max-width: 600px) {
      height: 500px;
    }

    @media screen and (max-width: 400px) {
      height: 400px;
    }
  }
  .AboutUsDetail {
    @include fontSizeNormal();
    @include maecenasFont();
    color: $ppbText2;
    padding: 20px 60px 20px 20px;
    max-width: 800px;
    margin: 0px auto;

    &::first-letter {
      @include fontSizeTitle();
      @include luminariFont();
      color: $ppbText;
    }

    @media screen and (max-width: 500px) {
      padding: 20px;
    }
  }

  .ContactUs {
    width: 100%;
    height: 1000px;

    background-image: url("../images/PPB/ContactUs.jpg");
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    position: relative;
    text-align: center;

    .ContactButton {
      position: absolute;
      top: 500px;
      width: 220px;
      height: 44px;
      margin: 0px auto;
      left: calc(50% - 110px);
      background-color: $ppbBackground;
      border-radius: 22px;
      @include fontSizeMedium();
      @include maecenasFont();
      color: #213538;
      padding-top: 10px;

      @media screen and (max-width: 1550px) {
        top: 470px;
      }
  
      @media screen and (max-width: 1440px) {
        top: 420px;
      }
  
      @media screen and (max-width: 1200px) {
        top: 350px;
      }
  
      @media screen and (max-width: 1024px) {
        top: 300px;
      }
  
      @media screen and (max-width: 850px) {
        top: 230px;
      }
  
      @media screen and (max-width: 400px) {
        top: 200px;
      }
    }

    @media screen and (max-width: 1550px) {
      height: 950px;
    }

    @media screen and (max-width: 1440px) {
      height: 850px;
    }

    @media screen and (max-width: 1200px) {
      height: 700px;
    }

    @media screen and (max-width: 1024px) {
      height: 600px;
    }

    @media screen and (max-width: 850px) {
      height: 500px;
    }

    @media screen and (max-width: 400px) {
      height: 400px;
    }
  }
  .ContactUsDetail {
    @include fontSizeNormal();
    @include maecenasFont();
    color: $ppbText2;
    padding: 20px 60px 20px 20px;
    max-width: 800px;
    margin: 0px auto;

    &::first-letter {
      @include fontSizeTitle();
      @include luminariFont();
      color: $ppbText;
    }

    @media screen and (max-width: 500px) {
      padding: 20px;
    }
  }

  .PumpkinFestival {
    width: 100%;
    height: 1000px;

    background-image: url("../images/PPB/PumpkinSection.jpg");
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;

    div {
      visibility: hidden;
    }

    @media screen and (max-width: 2300px) {
      height: 900px;
    }

    @media screen and (max-width: 1700px) {
      height: 650px;
    }

    @media screen and (max-width: 1500px) {
      height: 600px;
    }

    @media screen and (max-width: 1200px) {
      height: 450px;
    }
    @media screen and (max-width: 600px) {
      height: 350px;
    }

    @media screen and (max-width: 400px) {
      height: 320px;
    }
  }
  .IceSkating {
    width: 100%;
    height: 1000px;

    background-image: url("../images/PPB/IceSkatingSection.jpg");
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;

    div {
      visibility: hidden;
    }

    @media screen and (max-width: 2300px) {
      height: 900px;
    }

    @media screen and (max-width: 1700px) {
      height: 650px;
    }

    @media screen and (max-width: 1500px) {
      height: 600px;
    }

    @media screen and (max-width: 1200px) {
      height: 450px;
    }
    @media screen and (max-width: 600px) {
      height: 350px;
    }

    @media screen and (max-width: 400px) {
      height: 320px;
    }
  }
  .WinterWonderland {
    width: 100%;
    height: 1000px;

    background-image: url("../images/PPB/WinterWonderlandSection.png");
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;

    div {
      visibility: hidden;
    }

    @media screen and (max-width: 2300px) {
      height: 900px;
    }

    @media screen and (max-width: 1700px) {
      height: 650px;
    }

    @media screen and (max-width: 1500px) {
      height: 600px;
    }

    @media screen and (max-width: 1200px) {
      height: 450px;
    }
    @media screen and (max-width: 600px) {
      height: 350px;
    }

    @media screen and (max-width: 400px) {
      height: 320px;
    }
  }
  .Drones {
    width: 100%;
    height: 1000px;

    background-image: url("../images/PPB/DroneShowSection.jpg");
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;

    div {
      visibility: hidden;
    }

    @media screen and (max-width: 2300px) {
      height: 900px;
    }

    @media screen and (max-width: 1700px) {
      height: 650px;
    }

    @media screen and (max-width: 1500px) {
      height: 600px;
    }

    @media screen and (max-width: 1200px) {
      height: 450px;
    }
    @media screen and (max-width: 600px) {
      height: 350px;
    }

    @media screen and (max-width: 400px) {
      height: 320px;
    }
  }
  .FunFleet {
    width: 100%;
    height: 1000px;

    background-image: url("../images/PPB/FunFleetSection.jpg");
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;

    div {
      visibility: hidden;
    }

    @media screen and (max-width: 2300px) {
      height: 900px;
    }

    @media screen and (max-width: 1700px) {
      height: 650px;
    }

    @media screen and (max-width: 1500px) {
      height: 600px;
    }

    @media screen and (max-width: 1200px) {
      height: 450px;
    }
    @media screen and (max-width: 600px) {
      height: 350px;
    }

    @media screen and (max-width: 400px) {
      height: 320px;
    }
  }
}

.ReactGridGallery div {
  justify-content: center;
}