// main: ../master.scss

//NUNITO
@font-face {
    font-family: Nunito;
    src: url('../../resources/partials/fonts/Nunito-Regular.ttf');
    font-weight:300;
    font-style: normal;
}
@font-face {
    font-family: Nunito;
    src: url('../../resources/partials/fonts/Nunito-Bold.ttf');
    font-weight:600;
    font-style: normal;
}

//Gangster Grotesk
@font-face {
    font-family: gangstergrotesk;
    src: url('../../resources/partials/fonts/gangstergrotesk-regular.ttf');
    font-weight:300;
    font-style: normal;
}
@font-face {
    font-family: gangstergrotesk;
    src: url('../../resources/partials/fonts/gangstergrotesk-bold.ttf');
    font-weight:600;
    font-style: normal;
}

//Maecenas
@font-face {
    font-family: maecenas;
    src: url('../../resources/partials/fonts/maecenas.otf');
    font-weight:300;
    font-style: normal;
}

//Luminari
@font-face {
    font-family: luminari;
    src: url('../../resources/partials/fonts/Luminari.ttf');
    font-weight:300;
    font-style: normal;
}
