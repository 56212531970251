// main: ../master.scss
// The dark themed sidebar for the dashboard
.ManageUser {
  @include fadeIn();
  background: $whiteColor;
  min-height: 300px;

  .FormBlock {
    padding: 50px $mainPaddingSize;
    background: $offWhiteColor;

    .ManageUserTitle {
      @include fontSizeTitle();
      padding-bottom: 25px;

      img {
        height: 30px;
        margin-right: 20px;
        background: $whiteColor;
        padding: 5px;
        border: 1px solid $grayColor0;
        border-radius: 15px;
        @include pushable();
        transition: all 300ms;

        &:hover {
          background: $grayColor0;
        }
      }
    }

    .ManageUserContent {
      width: 100%;
      text-align: left;

      .ManageUserContentError {
        @include fontSizeStat();
        color: $themeColor3;
      }

      .ManageUserContentTitle {
        @include fontSizeStat();
        font-weight: bold;
        color: $darkColor;
        padding-bottom: 12px;
        display: inline-block;
        @include pushable();

        .ManageUserContentTitleArrow {
          padding: 0px 8px;
        }

        .ManageUserContentSubloader {
          display: inline-block;
          padding-left: 12px;
          img {
            height: 30px;
            @include spin(2s);
          }
        }
      }
      .ManageUserContentSubTitle {
        @include fontSizeMedium();
        font-weight: bold;
        color: $darkColor;
        padding-top: 12px;
        padding-bottom: 8px;
      }
      .ManageUserContentUI {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        padding-bottom: 32px;
        .ManageUserContentUILeft {
          img {
            width: 100px;
            max-height: 100px;
            object-fit: contain;
            border-radius: 22px;
          }
          flex-grow: 0;
        }
        .ManageUserContentUIRight {
          padding-left: 12px;
          .ManageUserContentUIRightPhoneNumber {
            @include fontSizeNormal();
            color: $darkColor;
            @include pushable();
          }
          .ManageUserContentUIRightEmail {
            @include fontSizeNormal();
            color: $darkColor;
            @include pushable();
          }
          .ManageUserContentUIRightAddress {
            @include fontSizeNormal();
            color: $darkColor;
            @include pushable();
          }
          .ManageUserContentUIRightBreak {
            width: 100%;
            height: 1px;
            background-color: black;
            margin: 8px 0px;
          }
          .ManageUserContentUIRightPushNotifications {
            @include fontSizeTiny();
            font-weight: bold;
            color: $grayColor1;
            text-transform: uppercase;
          }
          .ManageUserContentUIRightBlock {
            @include fontSizeTiny();
            color: $grayColor0;

            .ManageUserContentUIRightBlockValue {
              color: $grayColor1;
              @include pushable();
            }
          }
        }
      }
      .ManageUserContentSettings {
        padding-bottom: 32px;
      }
      .ManageUserContentPaymentSettings {
        padding-bottom: 32px;
      }
      .ManageUserContentBank {
        padding-bottom: 32px;

        .ManageUserContentBankInfo {
          @include fontSizeNormal();
          color: $grayColor1;

          .ManageUserContentBankInfoBank {
            padding: 12px 0px 12px 0px;
            border-bottom: 1px solid $grayColor0;

            .ManageUserContentBankInfoName {
              @include fontSizeMedium();
              color: $grayColor1;
            }
            .ManageUserContentBankInfoAccount {
              .ManageUserContentBankInfoAccountNumber {
                display: inline-block;
                @include pushable();
                color: $darkColor;
                font-weight: bold;
              }
            }
            .ManageUserContentBankInfoRouting {
              .ManageUserContentBankInfoRoutingNumber {
                display: inline-block;
                @include pushable();
                color: $darkColor;
                font-weight: bold;
              }
            }
          }
        }

        .ManageUserContentBankNotLinked {
          @include fontSizeNormal();
          color: $grayColor1;
        }
        .ManageUserContentBankNotLinkedDetails {
          @include fontSizeNormal();
          @include pushable();
          color: $blueColor;
        }
      }
      .ManageUserContentInfo {
        padding-bottom: 32px;
        .ManageUserContentInfoBalance {
          @include fontSizeNormal();
          color: $grayColor1;

          .ManageUserContentInfoBalanceValue {
            @include fontSizeNormal();
            //font-weight: bold;
            color: $darkColor;
          }
        }
        .ManageUserContentInfoBalanceAvailable {
          .ManageUserContentInfoBalanceValue {
            color: $themeColor1;
          }
        }
        .ManageUserContentInfoBalanceGeojis {
          display: block;
          flex-basis: 100%;
          padding-top: 16px;
          .ManageUserContentInfoBalanceGeoji {
            @include fontSizeNormal();
            color: $darkColor;
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 8px;
            cursor: pointer;
            @include noselect();

            .ManageUserContentInfoBalanceGeojiEmoji {
              font-size: 34px;
              padding: 10px;
              width: 68px;
              text-align: center;
              background: $whiteColor;
              border-radius: 18px;
            }
            .ManageUserContentInfoBalanceGeojiRight {
              display: flex;
              flex-flow: column wrap;
              justify-content: center;
              align-items: flex-start;
              padding-left: 8px;
              .ManageUserContentInfoBalanceGeojiRightTitle {
              }
              .ManageUserContentInfoBalanceGeojiRightAvailableRevenue {
                color: $grayColor1;
                .ManageUserContentInfoBalanceGeojiRightRevenueValue {
                  color: $themeColor1;
                }
              }
              .ManageUserContentInfoBalanceGeojiRightFutureRevenue {
                color: $grayColor1;
                .ManageUserContentInfoBalanceGeojiRightRevenueValue {
                  color: $darkColor;
                }
              }
              .ManageUserContentInfoBalanceGeojiRightSalesRevenue {
                color: $grayColor1;
                .ManageUserContentInfoBalanceGeojiRightRevenueValue {
                  color: $darkColor;
                }
              }
            }
          }
        }
        hr {
          border-top: 1px solid $grayColor0;
          margin: 12px 0px;
        }
        .ManageUserContentInfoAdjustment {
          display: flex;
          flex-flow: row wrap;
          justify-content: flex-start;
          align-items: flex-start;
          @include fontSizeNormal();
          color: $darkColor;
          padding: 4px 0px;
          margin-bottom: 8px;
          border-bottom: 1px solid $grayColor0;

          .ManageUserContentInfoAdjustmentDate {
            padding-right: 8px;
            min-width: 110px;
            color: $grayColor1;
          }
          .ManageUserContentInfoAdjustmentTotal {
            padding-right: 8px;
            min-width: 80px;
            color: $themeColor1;
          }
          .ManageUserContentInfoAdjustmentNote {
          }
        }
        .ManageUserContentInfoBA {

        }
      }
      .ManageUserContentPayouts {
        padding-bottom: 32px;

        .ManageUserContentInfoPayout {
          display: flex;
          flex-flow: row wrap;
          justify-content: flex-start;
          align-items: center;
          @include fontSizeNormal();
          color: $darkColor;
          padding: 4px 0px;
          margin-bottom: 8px;
          border-bottom: 1px solid $grayColor0;

          .ManageUserContentInfoPayoutDate {
            cursor: pointer;
            padding-right: 8px;
            min-width: 110px;
            color: $grayColor1;
          }
          .ManageUserContentInfoPayoutValue {
            padding-right: 8px;
            min-width: 60px;
          }
          .ManageUserContentInfoPayoutValueRequested {
            color: $themeColor1;
          }
          .ManageUserContentInfoPayoutStatus {
            padding-right: 8px;
            min-width: 50px;
            color: $themeColor3;
          }
          .ManageUserContentInfoPayoutStatusPaid {
            color: $themeColor2;
          }
          .ManageUserContentInfoPayoutType {
            color: $grayColor0;
          }
          .ManageUserContentInfoPayoutACHStatus {
            padding-left: 8px;
            color: $themeColor2;
          }
          .ManageUserContentInfoPayoutACHID {
            padding-left: 8px;
            color: $darkColor;
          }

          .ManageUserContentInfoPayoutACH {
            display: block;
            flex-basis: 100%;
            padding-top: 16px;

            .ManageUserContentInfoPayoutACHEDate {
              color: $grayColor1;
              font-weight: bold;
            }
            .ManageUserContentInfoPayoutACHEService {
              color: $grayColor1;
            }
            .ManageUserContentInfoPayoutACHEStatus {
              color: $themeColor1;
            }
          }

          .ManageUserContentInfoPayoutGeojis {
            display: block;
            flex-basis: 100%;
            padding-top: 16px;
            .ManageUserContentInfoPayoutGeoji {
              @include fontSizeNormal();
              color: $darkColor;
              display: flex;
              flex-flow: row nowrap;
              justify-content: flex-start;
              align-items: center;
              margin-bottom: 8px;
              cursor: pointer;
              @include noselect();

              .ManageUserContentInfoPayoutGeojiEmoji {
                font-size: 34px;
                padding: 10px;
                width: 68px;
                text-align: center;
                background: $whiteColor;
                border-radius: 18px;
              }
              .ManageUserContentInfoPayoutGeojiRight {
                display: flex;
                flex-flow: column wrap;
                justify-content: center;
                align-items: flex-start;
                padding-left: 8px;
                .ManageUserContentInfoPayoutGeojiRightTitle {
                }
                .ManageUserContentInfoPayoutGeojiRightNotes {
                  color: $grayColor1;
                }
                .ManageUserContentInfoPayoutGeojiRightRevenue {
                  color: $themeColor1;
                }
                .ManageUserContentInfoPayoutGeojiRightSales {
                  color: $grayColor1;
                }
              }
            }
            .ManageUserContentInfoPayoutGeojiAdjustment {
              .ManageUserContentInfoPayoutGeojiEmoji {
                background: lighten($themeColor1, 30%);
              }
            }
          }
        }
        .ManageUserContentInfoPayoutEmpty {
          @include fontSizeNormal();
          color: $grayColor0;
        }
      }
      .ManageUserContentGeojis {
        padding-bottom: 32px;

        .ManageUserContentGeoji {
          @include fontSizeNormal();
          color: $darkColor;
          display: flex;
          flex-flow: row nowrap;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 8px;
          @include noselect();

          .ManageUserContentGeojiEmoji {
            font-size: 34px;
            padding: 10px;
            width: 68px;
            text-align: center;
            background: $whiteColor;
            border-radius: 18px;
            cursor: pointer;
          }
          .ManageUserContentGeojiRight {
            display: flex;
            flex-flow: column wrap;
            justify-content: center;
            align-items: flex-start;
            padding-left: 8px;
            .ManageUserContentGeojiRightTitle {
              .ManageUserContentGeojiRightDuplicate {
                @include fontSizeSmall();
                background: $themeColor1;
                padding: 2px 8px;
                border-radius: 8px;
                color: $whiteColor;
                display: inline-block;
                margin-left: 16px;
                cursor: pointer;
              }
            }
            .ManageUserContentGeojiRightDescription {
              color: $grayColor1;
            }
            .ManageUserContentGeojiRightRevenue {
              color: $themeColor1;
            }
            .ManageUserContentGeojiRightDate {
              color: $grayColor1;
            }
            .ManageUserContentGeojiRightSplit {
              color: $grayColor1;
              .ManageUserContentGeojiRightSplitValue {
                color: $themeColor2;
                padding-left: 4px;
              }
              .ManageUserContentGeojiRightSplitValue2 {
                padding-left: 4px;
                color: $themeColor1;
              }
            }
            .ManageUserContentGeojiRightFraud {
              @include fontSizeSmall();
              color: $grayColor0;
              margin-bottom: 8px;
              border-top: 1px solid $grayColor0;
              display: flex;
              flex-flow: row nowrap;

              .ManageUserContentGeojiRightFraudLeft {
                width: 30px;
                height: 30px;

                img {
                  object-fit: contain;
                  width: calc(100% - 8px);
                  height: calc(100% - 8px);
                  margin: 4px;
                }
              }
              .ManageUserContentGeojiRightFraudRight {
                padding-left: 4px;
                .ManageUserContentGeojiRightFraudMetric {
                  color: $grayColor1;
                }
              }
            }
            .ManageUserContentGeojiRightNoFraud {
              @include fontSizeSmall();
                background: $grayColor0;
                padding: 2px 8px;
                border-radius: 8px;
                color: $whiteColor;
                display: inline-block;
                cursor: pointer;
            }
          }
        }

        .ManageUserContentSession {
          @include fontSizeNormal();
          color: $darkColor;
          padding-bottom: 8px;
          margin-bottom: 8px;
          border-bottom: 1px solid $grayColor0;

          .ManageUserContentSessionDate {
            @include fontSizeMedium();
            color: $darkColor;
            display: inline-block;
            padding-bottom: 4px;
          }
          .ManageUserContentSessionBrowser {
            @include fontSizeNormal();
            color: $themeColor1;
            display: inline-block;
            padding-bottom: 4px;
          }
          .ManageUserContentSessionIPAddress {
            @include fontSizeMid();
            color: $themeColor2;
            text-decoration: underline;
            @include pushable();
            display: inline-block;
            padding-bottom: 4px;
          }
          .ManageUserContentSessionUserAgent {
            @include fontSizeMid();
            color: $grayColor1;
            display: inline-block;

            span {
              color: $grayColor0;
            }
          }
        }
      }
    }
  }

  @media (max-width: $breakSmall) {
    min-height: 300px;
    .FormBlock {
      padding: 20px $breakPaddingSize;

      .ManageUserContent {
      }
    }
  }
}
