.Footer {
  background: $ppbBackground;
  padding: 25px;
  max-width: $maxWidth;
  margin: 0px auto;

  color: $ppbText;
  @include fontSizeNormal();
  @include maecenasFont();

  .FooterContent {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start;

    .FooterContentSection {
      padding: 0px 10px 25px 0px;

      .FooterLogo {
        height: 30px;
      }
      .FooterDescription {
        padding-top: 10px;
        color: $ppbText;
      }
      .FooterTitle {
        color: $ppbText;
        font-weight: 600;
      }
      .FooterLink {
        color: $ppbText;
        padding-top: 10px;
        @include pushable();

        &:hover {
          color: $ppbLink;
        }
      }
      .FooterIcons {
        padding-top: 10px;
        .FooterIcon {
          @include pushable();
          height: 24px;
          margin-right: 8px;
        }
      }
    }
  }
  .FooterLine {
    margin: 0px 0px 25px 0px;
    border-top: 1px solid $ppbText2;
  }
  .FooterBottom {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;

    .copyright {

    }
    .legalLinks {
      display: flex;
      flex-flow: row wrap;

      .legalLink {
        padding: 0px 10px;
        @include pushable();

        &:hover {
          color: $ppbLink;
        }
      }
    }
  }
}
