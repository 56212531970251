// main: ../master.scss
// The dark themed sidebar for the dashboard
.CreateGeoji {
  @include fadeIn();
  background: $whiteColor;
  min-height: 300px;

  .CreatedGeoji {
    text-align: center;
    .CreatedGeojiTitle {
      @include fontSizeTitle();
    }
    .CreatedGeojiDescription {
      @include fontSizeNormal();
      padding: 24px 0px;
    }
  }

  .FormBlock {
    padding: 50px $mainPaddingSize;
    background: $offWhiteColor;

    .CreateGeojiTitle {
      @include fontSizeTitle();
      padding-bottom: 25px;

      img {
        height: 30px;
        margin-right: 20px;
        background: $whiteColor;
        padding: 5px;
        border: 1px solid $grayColor0;
        border-radius: 15px;
        @include pushable();
        transition: all 300ms;

        &:hover {
          background: $grayColor0;
        }
      }
    }

    .CreateGeojiContent {
      width: 100%;

      .FileSelectorDiv {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        .FileSelectorUploadInput {
        }
        .FileSelectorUploadInputLabel {
          background-color: $whiteColor;
          border-radius: 32px;
          max-width: 320px;
          text-align: center;
          border: 2px solid $grayColor0;

          .GeojiCnAdminSendFreeTokensMultipleButton {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            img {
              margin-right: 8px;
            }
            div {
              color: $darkColor;
            }
          }
        }
      }
    }
  }

  @media (max-width: $breakSmall) {
    min-height: 300px;
    .FormBlock {
      padding: 20px $breakPaddingSize;

      .CreateGeojiContent {
      }
    }
  }
}