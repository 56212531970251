// main: ../master.scss
// The dark themed sidebar for the dashboard
.ManageDispute {
  @include fadeIn();
  background: $whiteColor;
  min-height: 300px;

  .FormBlock {
    padding: 50px $mainPaddingSize;
    background: $offWhiteColor;

    .ManageDisputeTitle {
      @include fontSizeTitle();
      padding-bottom: 25px;

      img {
        height: 30px;
        margin-right: 20px;
        background: $whiteColor;
        padding: 5px;
        border: 1px solid $grayColor0;
        border-radius: 15px;
        @include pushable();
        transition: all 300ms;

        &:hover {
          background: $grayColor0;
        }
      }
    }

    .ManageDisputeContent {
      width: 100%;
      text-align: left;

      .ManageDisputeContentSplit {
        display: flex;
        flex-flow: row wrap-reverse;
        justify-content: flex-start;
        align-items: flex-end;

        .ManageDisputeContentSplitLeft {
          width: calc(100% - 320px);
        }
        .ManageDisputeContentSplitRight {
          width: 320px;
          text-align: center;
          .ManageDisputeContentSubTitle {
            text-align: center;
            color: $themeColor2;
            text-decoration: underline;
            display: inline-block;
            @include pushable();
          }
          iframe {
            width: 320px;
            height: 600px;
            border: 0px;
            padding: 0px;
            margin: 0px;
          }
        }
        @media (max-width: $breakSmall) {
          .ManageDisputeContentSplitLeft {
            width: 100%;
          }
          .ManageDisputeContentSplitRight {
            width: 100%;
            text-align: center;
          }
        }
      }

      .ManageDisputeContentError {
        @include fontSizeStat();
        color: $themeColor3;
      }
      .ManageDisputeContentTitle {
        @include fontSizeStat();
        font-weight: bold;
        color: $darkColor;
        padding-bottom: 12px;
        display: inline-block;
        @include pushable();

        .ManageDisputeContentTitleArrow {
          padding: 0px 8px;
        }

        .ManageDisputeContentSubloader {
          display: inline-block;
          padding-left: 12px;
          img {
            height: 30px;
            @include spin(2s);
          }
        }
      }
      .ManageDisputeContentSubTitle {
        @include fontSizeMedium();
        font-weight: bold;
        color: $darkColor;
        padding-top: 12px;
        padding-bottom: 8px;
      }
      .ManageDisputeContentSubpanel {
        .ManageDisputeContentSubpanelLink {
          @include fontSizeNormal();
          font-weight: bold;
          padding-bottom: 8px;
          color: $themeColor2;
          text-decoration: underline;
          display: inline-block;
          @include pushable();
        }
        .ManageDisputeContentSubpanelKey {
          @include fontSizeNormal();
          font-weight: bold;
          color: $darkColor;
        }
        .ManageDisputeContentSubpanelValue {
          padding-bottom: 8px;
          @include fontSizeNormal();
          color: $grayColor1;
        }
        .ManageDisputeContentSubpanelValueTheme {
          color: $themeColor1;
        }
        .ManageDisputeContentAction {
          @include pushable();
          display: inline-block;
          background: $themeColor1;
          padding: 4px 12px;
          border-radius: 12px;
          margin-right: 8px;
          color: $whiteColor;
        }
      }
      .ManageDisputeMetadataSettings {
        padding-bottom: 32px;

        .AuthorBlockHeader {
          @include fontSizeMid();
          color: $darkColor;
        }
        .AuthorBlock {
          padding: 12px 0px;
          display: flex;
          flex-flow: row nowrap;
          justify-content: flex-start;
          align-items: center;

          .AuthorBlockImage {
            img {
              width: 60px;
              height: 60px;
              object-fit: cover;
              border-radius: 30px;
            }
          }
          .AuthorBlockName {
            @include fontSizeNormal();
            color: $grayColor0;
            padding-left: 12px;
          }
        }
      }
    }
  }

  @media (max-width: $breakSmall) {
    min-height: 300px;
    .FormBlock {
      padding: 20px $breakPaddingSize;

      .ManageDisputeContent {
      }
    }
  }
}
