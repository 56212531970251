// main: ../master.scss

.dualImage {
  cursor: pointer;
}

.CopyBox {
  background: $whiteColor;
  @include pushable();
  border: 1px solid $offWhiteColor2;
  border-radius: 9px;
  display: inline-block;
  overflow: hidden;

  .CopyBoxText {
    display: inline-block;
    padding: 7px 20px;
    @include ellipsis();
    color: $grayColor1;
    max-width: 200px;
  }
  .CopyBoxResult {
    display: inline-block;
    color: $whiteColor;
    background: $darkColor;
    padding: 7px 10px;
    width: 64px;
    text-align: center;
  }
  .CopyBoxResultCopied {
    background: $grayColor1;
  }
}

.CopyBox2 {
  @include pushable();
  display: inline-block;
  padding: 0px 0px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  .CopyBox2Text {
    color: $darkColor;
    @include fontSizeNormal();
    display: inline-block;
    padding-right: 15px;
  }
}

.GoogleMapsDiv {
  overflow: hidden;
  background: $offWhiteColor;
  border-radius: 8px;
}
.GoogleMapsLoading {
}
.GoogleMapsContainer {
}
.GoogleMapsItem {
}

/*!
 * Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
 * Copyright 2015 Daniel Cardoso <@DanielCardoso>
 * Licensed under MIT
 */
.la-ball-scale-multiple,
.la-ball-scale-multiple > div {
    position: relative;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}
.la-ball-scale-multiple {
    display: block;
    text-align: center;
    margin: 0px auto;
    font-size: 0;
    color: $whiteColor;
}
.la-ball-scale-multiple.la-dark {
    color: $grayColor3;
}
.la-ball-scale-multiple.la-purple {
    color: $purpleColor;
}
.la-ball-scale-multiple.la-light {
    color: $offWhiteColor;
}
.la-ball-scale-multiple > div {
    display: inline-block;
    float: none;
    background-color: currentColor;
    border: 0 solid currentColor;
}
.la-ball-scale-multiple {
    width: 32px;
    height: 32px;
}
.la-ball-scale-multiple > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    opacity: 0;
    -webkit-animation: ball-scale-multiple 1s 0s linear infinite;
       -moz-animation: ball-scale-multiple 1s 0s linear infinite;
         -o-animation: ball-scale-multiple 1s 0s linear infinite;
            animation: ball-scale-multiple 1s 0s linear infinite;
}
.la-ball-scale-multiple > div:nth-child(2) {
    -webkit-animation-delay: .2s;
       -moz-animation-delay: .2s;
         -o-animation-delay: .2s;
            animation-delay: .2s;
}
.la-ball-scale-multiple > div:nth-child(3) {
    -webkit-animation-delay: .4s;
       -moz-animation-delay: .4s;
         -o-animation-delay: .4s;
            animation-delay: .4s;
}
.la-ball-scale-multiple.la-sm {
    width: 16px;
    height: 16px;
}
.la-ball-scale-multiple.la-sm > div {
    width: 16px;
    height: 16px;
}
.la-ball-scale-multiple.la-2x {
    width: 64px;
    height: 64px;
}
.la-ball-scale-multiple.la-2x > div {
    width: 64px;
    height: 64px;
}
.la-ball-scale-multiple.la-3x {
    width: 96px;
    height: 96px;
}
.la-ball-scale-multiple.la-3x > div {
    width: 96px;
    height: 96px;
}
/*
 * Animation
 */
@-webkit-keyframes ball-scale-multiple {
    0% {
        opacity: 0;
        -webkit-transform: scale(0);
                transform: scale(0);
    }
    5% {
        opacity: .75;
    }
    100% {
        opacity: 0;
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}
@-moz-keyframes ball-scale-multiple {
    0% {
        opacity: 0;
        -moz-transform: scale(0);
             transform: scale(0);
    }
    5% {
        opacity: .75;
    }
    100% {
        opacity: 0;
        -moz-transform: scale(1);
             transform: scale(1);
    }
}
@-o-keyframes ball-scale-multiple {
    0% {
        opacity: 0;
        -o-transform: scale(0);
           transform: scale(0);
    }
    5% {
        opacity: .75;
    }
    100% {
        opacity: 0;
        -o-transform: scale(1);
           transform: scale(1);
    }
}
@keyframes ball-scale-multiple {
    0% {
        opacity: 0;
        -webkit-transform: scale(0);
           -moz-transform: scale(0);
             -o-transform: scale(0);
                transform: scale(0);
    }
    5% {
        opacity: .75;
    }
    100% {
        opacity: 0;
        -webkit-transform: scale(1);
           -moz-transform: scale(1);
             -o-transform: scale(1);
                transform: scale(1);
    }
}


@keyframes ldio-2yvf4wa6rj5 {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.45,0,0.9,0.55)
  }
  0% {
    transform: translate(0,0)
  }
  50% {
    transform: translate(0,50px);
    animation-timing-function: cubic-bezier(0,0.45,0.55,0.9);
  }
  100% {
    transform: translate(0,0);
  }
}

.loadingio-spinner-ball-cm9vm1ej40l {
  width: 88px;
  height: 88px;
  display: inline-block;
  overflow: hidden;
  background: none;

  .ldio-2yvf4wa6rj5 div {
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: $themeColor1;
    left: 29px;
    top: 10px;
    animation: ldio-2yvf4wa6rj5 1s linear infinite;
  }
  .ldio-2yvf4wa6rj5 {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .ldio-2yvf4wa6rj5 div { box-sizing: content-box; }
}
.loadingio-spinner-ball-cm9vm1ej40l-dark {
  .ldio-2yvf4wa6rj5 div {
    background: #000000;
  }
}
.loadingio-spinner-ball-cm9vm1ej40l-dark {
  .ldio-2yvf4wa6rj5 div {
    background: $darkColor;
  }
}
.loadingio-spinner-ball-cm9vm1ej40l-light {
  .ldio-2yvf4wa6rj5 div {
    background: $whiteColor;
  }
}
.loadingio-spinner-ball-cm9vm1ej40l-medium {
  width: 200px;
  height: 200px;
  .ldio-2yvf4wa6rj5 div {
    width: 60px;
    height: 60px;
    left: 70px;
    top: 20px;
  }
}
.loadingio-spinner-ball-cm9vm1ej40l-large {
  width: 300px;
  height: 300px;
  .ldio-2yvf4wa6rj5 div {
    width: 90px;
    height: 90px;
    left: 105px;
    top: 30px;
  }
}
