// main: ../master.scss

// The dark themed sidebar for the dashboard
.Four0Four {
  @include fadeIn();
  background: $whiteColor;
  //min-height: calc(100% - 100px);

  .Four0FourContent {
    width: 100%;

    .Four0FourBackground {
      width: 100%;
      object-fit: cover;
    }
  }

  @media (max-width: $breakSmall) {
    //min-height: calc(100% - 48px);
  }
}
